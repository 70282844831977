var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container-xs",staticStyle:{"display":"grid","gap":"3rem","grid-template-rows":"auto auto 1fr","height":"100%"}},[_c('AppBackHeader',{attrs:{"to":"/account-recovery/token"}},[_vm._v(_vm._s(_vm.$t('account.reset-password')))]),_c('form',{staticClass:"x-form",staticStyle:{"display":"grid","gap":"0.5rem"},on:{"submit":function($event){$event.preventDefault();}}},[_c('x-label',{attrs:{"tag":"label","for":"password"}},[_vm._v(_vm._s(_vm.$t('account.new-password')))]),_c('form-input',{directives:[{name:"focus",rawName:"v-focus"}],attrs:{"id":"password","type":"password","required":"","minlength":"8","validator":value =>
          !value
            ? _vm.$t('form.password-required')
            : value.length < 8
            ? _vm.$t('form.password-too-short')
            : true},on:{"valid":_vm.forms.vset.reset.password},model:{value:(_vm.forms.reset.password),callback:function ($$v) {_vm.$set(_vm.forms.reset, "password", $$v)},expression:"forms.reset.password"}}),_c('x-label',{staticStyle:{"margin-top":"2rem"},attrs:{"tag":"label","for":"password-confirm"}},[_vm._v(_vm._s(_vm.$t('account.confirm-new-password')))]),_c('form-input',{attrs:{"id":"password-confirm","type":"password","validator":value =>
          !value
            ? _vm.$t('form.password-required')
            : value !== _vm.forms.reset.password
            ? _vm.$t('form.password-doesnt-match')
            : true,"required":""},on:{"valid":_vm.forms.vset.reset.confirmPassword},model:{value:(_vm.forms.reset.confirmPassword),callback:function ($$v) {_vm.$set(_vm.forms.reset, "confirmPassword", $$v)},expression:"forms.reset.confirmPassword"}})],1),_c('x-button',{staticStyle:{"margin-top":"auto"},attrs:{"id":"submit","type":"large","disabled":!_vm.forms.vget.reset.form},on:{"click":_vm.onPasswordReset}},[_vm._v(" "+_vm._s(_vm.$t('account.reset-password'))+" ")]),_c('modal',{ref:"successModal",staticStyle:{"height":"100vh","width":"100vw","text-align":"center"},attrs:{"x-class":"container-expand","x-style":"display: grid; height: 100vh; width: 100vw; align-content: center; justify-items: center; justify-content: center; position: relative"},on:{"hide":function($event){return _vm.$router.push('/login')}}},[_c('h3',{staticClass:"fs-48",staticStyle:{"font-family":"GTSuper"}},[_vm._v(" "+_vm._s(_vm.$t('settings.success'))+" ")]),_c('p',{staticStyle:{"width":"400px","margin-top":"1rem"}},[_vm._v(" Your password has been updated. ")]),_c('x-button',{staticStyle:{"margin-top":"2rem"},attrs:{"type":"large"},on:{"click":function($event){return _vm.$router.push('/login')}}},[_vm._v("Login")])],1),_c('InfoModal',{ref:"errorModal",attrs:{"title":"UH OH! LOOKS LIKE SOMETHING WENT WRONG.","hide-close-button":"","cta":"Try Again"},on:{"cta":function($event){return _vm.$refs.errorModal.hide()}}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <div
    style="
      display: grid;
      gap: 3rem;
      grid-template-rows: auto auto 1fr;
      height: 100%;
    "
    class="container-xs"
  >
    <AppBackHeader to="/account-recovery/token">{{
      $t('account.reset-password')
    }}</AppBackHeader>
    <form class="x-form" style="display: grid; gap: 0.5rem" @submit.prevent>
      <x-label tag="label" for="password">{{
        $t('account.new-password')
      }}</x-label>
      <form-input
        id="password"
        v-model="forms.reset.password"
        v-focus
        type="password"
        required
        minlength="8"
        :validator="
          value =>
            !value
              ? $t('form.password-required')
              : value.length < 8
              ? $t('form.password-too-short')
              : true
        "
        @valid="forms.vset.reset.password"
      />
      <x-label tag="label" for="password-confirm" style="margin-top: 2rem">{{
        $t('account.confirm-new-password')
      }}</x-label>
      <form-input
        id="password-confirm"
        v-model="forms.reset.confirmPassword"
        type="password"
        :validator="
          value =>
            !value
              ? $t('form.password-required')
              : value !== forms.reset.password
              ? $t('form.password-doesnt-match')
              : true
        "
        required
        @valid="forms.vset.reset.confirmPassword"
      />
    </form>
    <x-button
      id="submit"
      type="large"
      style="margin-top: auto"
      :disabled="!forms.vget.reset.form"
      @click="onPasswordReset"
    >
      {{ $t('account.reset-password') }}
    </x-button>
    <modal
      ref="successModal"
      style="height: 100vh; width: 100vw; text-align: center"
      x-class="container-expand"
      x-style="display: grid; height: 100vh; width: 100vw; align-content: center; justify-items: center; justify-content: center; position: relative"
      @hide="$router.push('/login')"
    >
      <h3 class="fs-48" style="font-family: GTSuper">
        {{ $t('settings.success') }}
      </h3>
      <p style="width: 400px; margin-top: 1rem">
        Your password has been updated.
      </p>
      <x-button
        type="large"
        style="margin-top: 2rem"
        @click="$router.push('/login')"
        >Login</x-button
      >
    </modal>
    <InfoModal
      ref="errorModal"
      title="UH OH! LOOKS LIKE SOMETHING WENT WRONG."
      hide-close-button
      cta="Try Again"
      @cta="$refs.errorModal.hide()"
    />
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import { mapMutations } from 'vuex'
import InfoModal from '../../components-rf/modals/InfoModal.vue'
import AppBackHeader from '../../components-rf/AppBackHeader.vue'

export default {
  name: 'AccountRecovery',
  components: { InfoModal, AppBackHeader },
  created() {
    const { token, email } = this.$route.query
    if (token) this.setToken(token)
    if (email) this.setEmail(email)
    if (!this.email) this.$router.push('/account-recovery')
    else if (!this.token) this.$router.push('/account-recovery/token')
  },
  data() {
    return {
      forms: {
        reset: {
          password: '',
          confirmPassword: '',
        },
      },
    }
  },
  methods: {
    ...mapMutations('RecoveryModule', ['setToken', 'setEmail']),
    ...mapActions('RecoveryModule', ['resetPassword']),
    async onPasswordReset() {
      const isValid = this.forms.vget.reset.form && this.token && this.email
      if (!isValid) return
      this.resetPassword({
        token: this.token,
        email: this.email,
        password: this.forms.reset.password,
      })
        .then(() => {
          if (this.isAddingSearchPayment) {
            this.$router.push('/login')
          } else this.$refs.successModal.show()
        })
        .catch(() => {
          this.$refs.errorModal.show()
        })
    },
  },
  computed: {
    ...mapState('RecoveryModule', ['token', 'email']),
    ...mapState('PersistModule', ['isAddingSearchPayment']),
  },
}
</script>

<style scoped lang="scss">
.x-form {
  @include m() {
    margin-top: 2rem;
  }
}
</style>
